import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Container from "../components/Container"
import Jumbotron from "../components/Jumbotron"
import MediaFigureLink from "../components/MediaFigureLink"
import Section from "../components/Section"
import Site from "../components/Site"
import { formatLink } from "../helpers"
import "../css/ministries.scss"

const MinistriesIndex = ({ title, heading, hrefPath, filters, appearance, children }) => (
  <StaticQuery
    render={data => {
      const ministries = data.allDatoCmsMinistry.edges.filter(ministry => filters.includes(ministry.node.slug))
      return (
        <Site
          title     = {title}
          className = "hcc-ministries"
        >
          <article data-datocms-noindex>
            <Section tag="header" className="hcc-ministries-intro">
              <Container>
                <Jumbotron content={heading}/>
              </Container>
            </Section>
            <Section className="hcc-ministries-index">
              <Container>
                <ul className="hcc-grid">
                  {children}
                  {ministries.map(({node : ministry}) => {
                    return (
                      <li key={`ministry-${ministry.slug}`} className={`is-${appearance}`}>
                        <MediaFigureLink
                          style  = {{ '--c-base-accent' : `var(--c-${ministry.slug})`}}
                          href   = {hrefPath === null ? formatLink(ministry.slug, 'm') : `/${hrefPath}/${ministry.slug}/`}
                          label  = {ministry.name}
                          figure = {ministry.slug}
                        />
                      </li>
                    )
                  })}
                </ul>
              </Container>
            </Section>
          </article>
        </Site>
      )
    }}
    query={graphql`
      query ComponentQueryMinistries {
        allDatoCmsMinistry(sort: {order: ASC, fields: priority}) {
          edges {
            node {
              ...MinistryFragment
            }
          }
        }
      }
    `}
  />
)

MinistriesIndex.defaultProps = {
  title      : 'Ministries',
  heading    : <>Explore our ministries to find events and groups just for&nbsp;you</>,
  filters    : ['men', 'women', 'students', 'children', 'worship', 'missions', 'life-groups', 'care'],
  hrefPath   : null,
  appearance : 'mini',
  children   : null,
}

MinistriesIndex.propTypes = {
  title      : PropTypes.string,
  heading    : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  filters    : PropTypes.array,
  hrefPath   : PropTypes.string,
  appearance : PropTypes.string,
  children   : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default MinistriesIndex
