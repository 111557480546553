import React from "react"
import MinistriesIndex from "../components/MinistriesIndex"

const CurriculumPage = () => (
  <MinistriesIndex
    title    = "Curriculum"
    heading  = {<>Keep up with the latest curriculum at&nbsp;Hillside</>}
    filters  = {['students', 'children']}
    hrefPath = "curriculum"
  />
)

export default CurriculumPage
